import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSpring, animated } from "react-spring";

import { setProfile } from "../../../features/ProfileSlice";
import { CustProfile } from "services/Api/Module/Customer";

import moment from "moment";

import Barcode from "react-barcode";

import PageHeader from "layouts/inc/PageHeader";
import PageTitle from "layouts/inc/PageTitle";

import MenuMember from "components/menu/MenuMember";

import Helper, {
  convertDateFormat,
  convertMobileNoFormat,
} from "services/helper";

import useTranslations from "../../../i18n/useTranslations";

const MyPoint = ({ from, to }) => {
  // console.log(from, to);

  const { number } = useSpring({
    from: { number: from },
    number: to,
    delay: 160,
    config: { mass: 1, tension: 20, friction: 8 },
  });

  return (
    <animated.span>
      {number.to((to) => Helper.FN.toNumberWithCommas(to.toFixed(0)))}
    </animated.span>
  );
};

const Profile = () => {
  const { t } = useTranslations(); // แปลภาษา

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  const setDataProfile = (newData) => {
    const tmpObj = { dataUser: newData, usePoint: false };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  const [oldPoint] = useState(
    stateProfile.dataUser.currentPoint
      ? parseInt(stateProfile.dataUser.currentPoint)
      : 0
  );
  const [isCardOpen, setIsCardOpen] = useState(false);

  const cardFront = useSpring({
    opacity: isCardOpen ? 0 : 1,
  });

  const cardBack = useSpring({
    opacity: isCardOpen ? 1 : 0,
  });

  const handleCardToggle = (e) => {
    e.preventDefault();
    // console.log("handleCardToggle", e.currentTarget);

    setIsCardOpen(!isCardOpen ? true : false);
  };

  /* First event render */
  const getCustProfile = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await CustProfile({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getCustProfile resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getCustProfile >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile, oldPoint);

    if (stateProfile.lineUid === null && stateProfile.custNo === null) {
      navigate("/");
    } else {
      getCustProfile({
        lineUid: stateProfile.lineUid,
        custNo: stateProfile.custNo,
      }).then((_rsDataCustProfile) => {
        // console.log("_rsDataCustProfile >>", _rsDataCustProfile);

        if (
          _rsDataCustProfile.status === true &&
          _rsDataCustProfile.result.length !== 0
        ) {
          setDataProfile({
            ...stateProfile.dataUser,
            ..._rsDataCustProfile.result,
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    // console.log(stateProfile, oldPoint);
  }, [stateProfile, oldPoint]);
  /* End First event render */

  useEffect(() => {
    // console.log(isCardOpen);

    if (isCardOpen === true) {
      document.getElementById("isCardOpenNow").textContent = moment().format(
        "DD/MM/YYYY HH:mm:ss"
      );
    }
  }, [isCardOpen]);

  return (
    <>
      <Helmet>
        <title>ห้างเพชรทองโกลด์เด้น 99 จำกัด</title>
        <meta name="title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          name="description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta name="keyword" content="" />
        <meta property="og:title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          property="og:description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="assets/img/share/share-cover.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="GOLDEN99" />
      </Helmet>

      <div id="pageProfile">
        <PageHeader />

        <main>
          <div className="wrapContent">
            <PageTitle titleText={t.pageTitle.profile} />

            <section id="pMembercardMain">
              <div className="bMembercard">
                <div className="bCardMain">
                  <div className="bCard">
                    <animated.div
                      style={{
                        ...cardFront,
                      }}
                    >
                      <div className="bFront">
                        <div className="bInfo">
                          <div className="bId">
                            ID: {stateProfile.custNo ? stateProfile.custNo : ""}
                          </div>
                          <div className="bText">
                            <p className="tPoint">
                              {t.profilePage.currentPoint}
                              {stateProfile.dataUser.currentPoint &&
                              parseInt(stateProfile.dataUser.currentPoint) !==
                                0 ? (
                                <MyPoint
                                  from={oldPoint}
                                  to={
                                    stateProfile.dataUser.currentPoint
                                      ? parseInt(
                                          stateProfile.dataUser.currentPoint
                                        )
                                      : 0
                                  }
                                />
                              ) : (
                                <span>0</span>
                              )}
                            </p>
                            <p className="tName">
                              {stateProfile.dataUser.fullname
                                ? stateProfile.dataUser.fullname
                                : ""}
                            </p>
                            <p className="tType">
                              {t.profilePage.type}:{" "}
                              {stateProfile.dataUser.custType
                                ? t.profilePage[
                                    `custType${stateProfile.dataUser.custType}`
                                  ]
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </animated.div>
                    <animated.div
                      style={{
                        ...cardBack,
                      }}
                    >
                      <div className="bBack" style={{ display: "block" }}>
                        <div className="bInfo">
                          <div className="bQR">
                            {stateProfile.custNo ? (
                              <Barcode
                                displayValue={false}
                                value={stateProfile.custNo}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="bText">
                            <span id="isCardOpenNow"></span>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: t.profilePage.remarkBarcode,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </animated.div>
                  </div>
                  <div className="bBtn">
                    <a id="btnViewQR" onClick={handleCardToggle}>
                      <animated.div
                        style={{
                          ...cardFront,
                        }}
                      >
                        <div className="btnView">
                          {t.profilePage.openBarcode}
                        </div>
                      </animated.div>
                      <animated.div
                        style={{
                          ...cardBack,
                        }}
                      >
                        <div className="btnClose" style={{ display: "block" }}>
                          {t.profilePage.closeBarcode}
                        </div>
                      </animated.div>
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <section id="pProfileMain">
              <div className="bProfile">
                <div className="bGroup">
                  <div className="bRow">
                    <p>{t.profilePage.memberId}:</p>
                    <p>{stateProfile.custNo ? stateProfile.custNo : ""}</p>
                  </div>
                  <div className="bRow">
                    <p>{t.profilePage.name}:</p>
                    <p>
                      {stateProfile.dataUser.fullname
                        ? stateProfile.dataUser.fullname
                        : ""}
                    </p>
                  </div>
                  <div className="bRow">
                    <p>{t.profilePage.type}:</p>
                    <p>
                      {stateProfile.dataUser.custType
                        ? t.profilePage[
                            `custType${stateProfile.dataUser.custType}`
                          ]
                        : ""}
                    </p>
                  </div>
                  <div className="bRow">
                    <p>{t.profilePage.birthDate}:</p>
                    <p>
                      {stateProfile.dataUser.birthDate
                        ? convertDateFormat(
                            stateProfile.dataUser.birthDate,
                            "d / m / y"
                          )
                        : ""}
                    </p>
                  </div>
                  <div className="bRow">
                    <p>{t.profilePage.mobileNo}:</p>
                    <p>
                      {stateProfile.dataUser.mobileNo
                        ? convertMobileNoFormat(
                            stateProfile.dataUser.mobileNo,
                            "-"
                          )
                        : ""}
                    </p>
                  </div>
                  <div className="bRow">
                    <p>{t.profilePage.totalPurchase}</p>
                    <p>
                      {stateProfile.dataUser.totalBuy
                        ? Helper.FN.toNumberWithCommas(
                            stateProfile.dataUser.totalBuy
                          )
                        : "0"}
                    </p>
                  </div>
                  <div className="bBtn">
                    <a
                      href="#"
                      onClick={handleGotoPage}
                      data-page="privacy-policy"
                    >
                      {t.profilePage.privacyPolicy}
                    </a>
                  </div>
                </div>
              </div>
            </section>

            <MenuMember pageActive={"profile"} />
          </div>
        </main>
      </div>
    </>
  );
};

export default Profile;
