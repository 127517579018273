import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTransition, animated } from "react-spring";

import { Transaction } from "services/Api/Module/Customer";

import PageHeader from "layouts/inc/PageHeader";
import PageTitle from "layouts/inc/PageTitle";

import MenuMember from "components/menu/MenuMember";
import CardWarranty from "components/card/CardWarranty";
import ModalWarrantyFilter from "components/modal/ModalWarrantyFilter";

import { convertDateFormat } from "services/helper";

import useTranslations from "../../../i18n/useTranslations";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const MyWarranty = ({ articleItem }) => {
  let height = 0;

  const transitions = useTransition(
    articleItem.map((data) => ({ ...data, y: (height += 208) - 208 })),
    {
      key: (item) => item.key,
      from: { height: 0, opacity: 0, transform: "translateY(30px)" },
      enter: ({ y, height }) => ({
        y,
        height,
        opacity: 1,
        transform: "translateY(0)",
      }),
      leave: { height: 0, opacity: 0, transform: "translateY(30px)" },
      update: ({ data, y, height }) => ({ data, y, height }),
    }
  );

  return (
    <div
      className="item-group"
      style={{ position: "relative", height: height }}
    >
      {transitions((style, item, t, index) => (
        <animated.div
          className="item-warranty"
          style={{
            ...style,
            zIndex: articleItem.length - index,
            willChange: "transform, height, opacity",
          }}
        >
          {item}
        </animated.div>
      ))}
    </div>
  );
};

const Warranty = () => {
  const stateProfile = useSelector((state) => state.profile);

  const { t } = useTranslations(); // แปลภาษา

  const navigate = useNavigate();

  /* Modal filter */
  const [configYear, setConfigYear] = useState({
    maxYear: moment().year(),
    minYear: moment().year(),
  });

  const [isModalShow, setIsModalShow] = useState(false);

  const handleOpenModalFilter = (e) => {
    e.preventDefault();

    setIsModalShow(true);
  };

  const handleCloseModalFilter = () => {
    setIsModalShow(false);
  };

  const handleSubmitFilter = (dataFilter) => {
    // console.log("dataFilter!! >>>", dataFilter);
    const _type = dataFilter.type !== "" ? dataFilter.type : "";
    const _year = dataFilter.year !== "" ? dataFilter.year : "";
    const _month = dataFilter.month !== "" ? parseInt(dataFilter.month) : "";

    let _filterItem = warrantyItem;

    if (_type !== "" || _year !== "" || _month !== "") {
      if (_type !== "") {
        _filterItem = _filterItem.filter((item) => {
          if (item.props.type.includes(_type)) {
            return item;
          }
        });
      }

      if (_year !== "") {
        _filterItem = _filterItem.filter((item) => {
          if (item.props.year.toString().includes(_year)) {
            return item;
          }
        });
      }

      if (_month !== "") {
        _filterItem = _filterItem.filter((item) => {
          if (item.props.month.includes(_month)) {
            return item;
          }
        });
      }

      setArticleItem(_filterItem);
    } else {
      setArticleItem(warrantyItem);
    }
  };
  /* End Modal filter */

  /* First event render */
  const [isLoading, setIsLoading] = useState(true);
  const [warrantyItem, setWarrantyItem] = useState([]);
  const [articleItem, setArticleItem] = useState([]);

  const getTransaction = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await Transaction({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getTransaction resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getTransaction >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile, stateTransaction);

    if (stateProfile.lineUid === null || stateProfile.custNo === null) {
      navigate("/");
    }

    getTransaction({
      id: stateProfile.id,
    }).then((_rsDataTransaction) => {
      // console.log("_rsDataTransaction >>", _rsDataTransaction);

      if (
        _rsDataTransaction.status === true &&
        _rsDataTransaction.result.length !== 0
      ) {
        if (
          typeof _rsDataTransaction.result.transactions !== "undefined" &&
          _rsDataTransaction.result.transactions.length > 0
        ) {
          const _length = _rsDataTransaction.result.transactions.length;
          const _transactions = _rsDataTransaction.result.transactions;
          // console.log(_transactions);

          for (let i = 0; i < _length; i++) {
            setWarrantyItem((prevArticleItem) => [
              ...prevArticleItem,
              <CardWarranty
                key={i + 1}
                year={moment(_transactions[i].createdDate).year()}
                month={moment(_transactions[i].createdDate).format("M")}
                day={moment(_transactions[i].createdDate).format("D")}
                tranNo={
                  _transactions[i].buyNo
                    ? _transactions[i].buyNo
                    : _transactions[i].changeNo
                }
                createdDate={convertDateFormat(
                  _transactions[i].createdDate,
                  "d/m/y"
                )}
                type={_transactions[i].type}
                branch={_transactions[i].branch}
                // grandTotal={Helper.FN.toNumberWithCommas(_transactions[i].grandTotal)}
                grandTotal={parseFloat(
                  _transactions[i].grandTotal
                ).toLocaleString()}
                point={_transactions[i].sbPoint}
                dataTransaction={_transactions[i]}
              />,
            ]);
          }
        }

        if (typeof _rsDataTransaction.result.year !== "undefined") {
          setConfigYear({
            maxYear: _rsDataTransaction.result.year.maxYear,
            minYear: _rsDataTransaction.result.year.minYear,
          });
        }
      } else {
        console.warn(_rsDataTransaction);
      }

      setIsLoading(false);
    });
  }, []);
  /* End First event render */

  useEffect(() => {
    if (warrantyItem) {
      // console.log(warrantyItem.length, warrantyItem);

      setArticleItem(warrantyItem);
    }
  }, [warrantyItem]);

  useEffect(() => {
    // console.log(articleItem.length, articleItem);

    // setTimeout(() => {
    //   window.scrollTo({ top: 0, behavior: "smooth" });
    // }, 0);
  }, [isLoading, articleItem, isModalShow]);

  return (
    <>
      <Helmet>
        <title>ห้างเพชรทองโกลด์เด้น 99 จำกัด</title>
        <meta name="title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          name="description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta name="keyword" content="" />
        <meta property="og:title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          property="og:description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="assets/img/share/share-cover.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="GOLDEN99" />
      </Helmet>

      <div id="pageWarranty">
        <PageHeader />

        <main>
          <div className="wrapContent">
            <PageTitle titleText={t.pageTitle.warranty} />

            <ModalWarrantyFilter
              open={isModalShow}
              onClose={handleCloseModalFilter}
              maxYear={configYear.maxYear}
              minYear={configYear.minYear}
              submitCallback={handleSubmitFilter}
            />

            <section id="pFilterMain">
              <div className="bFilter">
                <a
                  className="btnFilter"
                  href="#"
                  onClick={handleOpenModalFilter}
                >
                  {t.warrantyPage.filterTitle}
                </a>
              </div>
            </section>

            <section id="pListWarrantyMain">
              <div className="bListWarranty">
                {articleItem.length === 0 ? (
                  <div className="bNoData">
                    {isLoading ? (
                      <p>
                        <FontAwesomeIcon icon={faSpinner} spin /> Loading...
                      </p>
                    ) : (
                      <p>{t.warrantyPage.cardNotFound}</p>
                    )}
                  </div>
                ) : (
                  <>
                    {/* <div className="bGroup">{articleItem}</div> */}
                    <div className="bGroup">
                      <MyWarranty articleItem={articleItem} />
                    </div>
                    <div className="bNoData hide">
                      <p>{t.warrantyPage.cardSearchNotFound}</p>
                    </div>
                  </>
                )}
              </div>
            </section>

            <MenuMember pageActive={"warranty"} />
          </div>
        </main>
      </div>
    </>
  );
};

export default Warranty;
