import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetCoupon } from "../../../features/CouponSlice";

import icoCheck from "../../../assets/img/ci/coupon/ico-check.svg";
import icoCoupon from "../../../assets/img/ci/coupon/ico-coupon.svg";

import PageHeader from "layouts/inc/PageHeader";

import Helper, { convertDateFormat } from "services/helper";

import useTranslations from "../../../i18n/useTranslations";

import moment from "moment";

const CouponHistoryDetail = () => {
  const { t } = useTranslations(); // แปลภาษา

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);
  const stateCoupon = useSelector((state) => state.coupon);

  const resetDataCoupon = () => {
    dispatch(resetCoupon()); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goback */
  const navigate = useNavigate();

  const handleGoBack = () => {
    resetDataCoupon();

    navigate(-1);
  };
  /* End Handle goback */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateProfile, stateCoupon);

    if (stateProfile.lineUid === null || stateProfile.custNo === null) {
      navigate("/");
    }

    if (stateCoupon.id === 0 || stateCoupon.data.length === 9) {
      navigate("/coupon/history");
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>ห้างเพชรทองโกลด์เด้น 99 จำกัด</title>
        <meta name="title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          name="description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta name="keyword" content="" />
        <meta property="og:title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          property="og:description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="assets/img/share/share-cover.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="GOLDEN99" />
      </Helmet>

      <div id="pageCoupon-Use">
        <PageHeader />

        <main>
          <div className="wrapContent">
            <section id="pCouponDetails">
              <div className="bCouponDetails">
                <div className="bTitle">
                  <img src={icoCheck} alt="" />
                  <h1>
                    {stateCoupon.data.isUsed === "0"
                      ? `${t.couponHistoryDetailPage.couponExpired}`
                      : `${t.couponHistoryDetailPage.couponUsed}`}
                  </h1>
                  <p>
                    <span>
                      {t.lang === "en"
                        ? stateCoupon.data.nameEn
                        : stateCoupon.data.nameTh}
                    </span>
                    {t.couponHistoryDetailPage.detailForOrderAmount}{" "}
                    {Helper.FN.toNumberWithCommas(stateCoupon.data.minTotal)}.-
                  </p>
                </div>
                <div className="bPic">
                  <img
                    src={
                      stateCoupon.data.rawImage !== null
                        ? stateCoupon.data.rawImage
                        : icoCoupon
                    }
                    alt=""
                  />
                </div>
                <div className="bDetails">
                  <h2>{t.couponHistoryDetailPage.detail}</h2>
                  <div className="bRow">
                    <p>{t.couponHistoryDetailPage.couponCode}:</p>
                    <p>{stateCoupon.data.couponCode}</p>
                  </div>
                  <div className="bRow">
                    <p>{t.couponHistoryDetailPage.couponDatetimeRedeem}:</p>
                    <p>
                      {moment(stateCoupon.data.createdOn).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}{" "}
                      {t.lang === "en" ? "" : "น."}
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <a className="btn outline" href="#" onClick={handleGoBack}>
                  {t.btn.back}
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default CouponHistoryDetail;
