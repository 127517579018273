import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTransition, animated } from "react-spring";

import { CustCoupons } from "services/Api/Module/Customer";

import PageHeader from "layouts/inc/PageHeader";
import PageTitle from "layouts/inc/PageTitle";

import MenuMember from "components/menu/MenuMember";
import CardCoupon from "components/card/CardCoupon";
import TextLoader from "components/loader/TextLoader";

import icoCouponGray from "../../../assets/img/ci/coupon/ico-coupon-gray.svg";

import useTranslations from "../../../i18n/useTranslations";

const MyCoupon = ({ articleItem }) => {
  let height = 0;

  const transition = useTransition(
    articleItem.map((data) => ({
      ...data,
      y:
        (height += data.props.curHeight ? data.props.curHeight + 16 : 118) -
        (data.props.curHeight ? data.props.curHeight + 16 : 118),
    })),
    {
      key: (item) => item.key,
      from: { height: 0, opacity: 0, transform: "translateY(30px)" },
      enter: ({ y, height }) => ({
        y,
        height,
        opacity: 1,
        transform: "translateY(0)",
      }),
      leave: { height: 0, opacity: 0, transform: "translateY(30px)" },
      update: ({ data, y, height }) => ({ data, y, height }),
    }
  );

  return (
    <div
      className="item-group"
      style={{ position: "relative", height: height }}
    >
      {transition((style, item, t, index) => (
        <animated.div
          className="item-coupon"
          style={{
            ...style,
            zIndex: articleItem.length - index,
            willChange: "transform, height, opacity",
          }}
        >
          {item}
        </animated.div>
      ))}
    </div>
  );
};

const Coupon = () => {
  const { t } = useTranslations(); // แปลภาษา

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("handleGotoPage >>", e.target.dataset.page);

    const _page = e.target.dataset.page;
    navigate(_page);
  };
  /* End Handle goto page */

  /* Handle search */
  const [searchValue, setSearchValue] = useState("");

  const handleOnSearchChange = (e) => {
    // console.log("handleOnSearchChange >>", e.target.value);

    setSearchValue(e.target.value);
  };
  /* End Handle search */

  /* Handle sort */
  const [sortValue, setSortValue] = useState(null);

  const handleOnSortChange = (e) => {
    // console.log("handleOnSortChange >>", e.target.value);

    setSortValue(e.target.value);
  };
  /* End Handle filter */

  /* First event render */
  // console.log("useEffect !", stateProfile);

  const [isLoading, setIsLoading] = useState(true);

  const [initItem, setInitItem] = useState([]);
  const [curHeight, setCurHeight] = useState([]);

  const [couponItem, setCouponItem] = useState([]);
  const [articleItem, setArticleItem] = useState([]);

  const [isLang, setIsLang] = useState(t.lang);

  const getCustCoupons = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await CustCoupons({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getCustCoupons resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getCustCoupons >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    if (
      stateProfile.lineUid === null ||
      stateProfile.custNo === null ||
      stateProfile.custType === null
    ) {
      navigate("/");
    } else {
      getCustCoupons({
        custId: stateProfile.id,
        custType: stateProfile.custType,
      }).then((_rsDataCoupons) => {
        // console.log("_rsDataCoupons >>", _rsDataCoupons);

        if (_rsDataCoupons.status === true) {
          if (
            typeof _rsDataCoupons.result !== "undefined" &&
            _rsDataCoupons.result.length > 0
          ) {
            const _length = _rsDataCoupons.result.length;
            const _coupon = _rsDataCoupons.result;
            // console.log(_coupon);

            for (let i = 0; i < _length; i++) {
              setInitItem((prevInitItem) => [...prevInitItem, _coupon[i]]);
            }
          }
        } else {
          console.warn(_rsDataCoupons);
        }

        setIsLoading(false);
      });
    }
  }, []);
  /* End First event render */

  /* Set new height on resize */
  const setNewHeight = (index, height) => {
    // console.log(index, height);
    setCurHeight((prevCurHeight) => prevCurHeight.with(index, height));
  };
  /* End Set new height on resize */

  useEffect(() => {
    // console.log(initItem.length, initItem);

    if (initItem.length > 0) {
      for (let i = 0; i < initItem.length; i++) {
        setCouponItem((prevCouponItem) => [
          ...prevCouponItem,
          <CardCoupon
            key={i + 1}
            index={i}
            setNewHeight={setNewHeight}
            curHeight={curHeight[i]}
            toDate={initItem[i].to_date}
            nameEn={initItem[i].name_en}
            nameTh={initItem[i].name_th}
            minTotal={initItem[i].min_total}
            discount={initItem[i].discount}
            thumbnail={initItem[i].raw_image}
            point={initItem[i].point}
            dataCoupon={initItem[i]}
          />,
        ]);

        setCurHeight((prevCurHeight) => [...prevCurHeight, 0]);
      }
    }
  }, [initItem]);

  useEffect(() => {
    if (couponItem) {
      // console.log(couponItem.length, couponItem);

      let _tmpItem = couponItem;

      if (sortValue !== null) {
        // console.log(sortValue);

        switch (sortValue) {
          case "to_date":
            _tmpItem = [...couponItem].sort((a, b) => {
              return a.props.toDate > b.props.toDate ? 1 : -1;
            });
            break;

          case "discount":
            _tmpItem = [...couponItem].sort((a, b) => {
              return a.props.discount < b.props.discount ? 1 : -1;
            });
            break;

          default:
            break;
        }
      }

      if (searchValue !== "") {
        // console.log(searchValue);

        _tmpItem = _tmpItem.filter((item) => {
          if (t.lang === "en") {
            if (item.props.nameEn.toLowerCase().includes(searchValue)) {
              return item;
            }
          } else {
            if (item.props.nameTh.toLowerCase().includes(searchValue)) {
              return item;
            }
          }
        });
      }

      setArticleItem(_tmpItem);
    }
  }, [couponItem, sortValue, searchValue]);

  useEffect(() => {
    // console.log(curHeight.length, curHeight);

    if (curHeight.length > 0) {
      curHeight.map((val, idx) => {
        setCouponItem((prevCouponItem) =>
          prevCouponItem.with(
            idx,
            <CardCoupon
              key={couponItem[idx].key}
              index={idx}
              setNewHeight={setNewHeight}
              curHeight={val}
              toDate={couponItem[idx].props.toDate}
              nameEn={couponItem[idx].props.nameEn}
              nameTh={couponItem[idx].props.nameTh}
              minTotal={couponItem[idx].props.minTotal}
              discount={couponItem[idx].props.discount}
              thumbnail={couponItem[idx].props.thumbnail}
              point={couponItem[idx].props.point}
              dataCoupon={couponItem[idx].props.dataCoupon}
            />
          )
        );
      });
    }
  }, [curHeight, isLang]);

  useEffect(() => {
    // console.log(articleItem.length, articleItem);
  }, [isLoading, articleItem]);

  useEffect(() => {
    // console.log(t.lang, isLang);

    if (t.lang !== isLang) {
      setIsLang(t.lang);

      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 200);
    }
  }, [t.lang, isLang]);

  return (
    <>
      <Helmet>
        <title>ห้างเพชรทองโกลด์เด้น 99 จำกัด</title>
        <meta name="title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          name="description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta name="keyword" content="" />
        <meta property="og:title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          property="og:description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="assets/img/share/share-cover.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="GOLDEN99" />
      </Helmet>

      <div id="pageCoupon">
        <PageHeader />

        <main>
          <div className="wrapContent">
            <PageTitle titleText={t.pageTitle.coupon} />

            <section id="pSubmenuMain">
              <div className="bSubmenu">
                <a
                  className="active"
                  href="#"
                  data-page="/coupon"
                  onClick={handleGotoPage}
                >
                  {t.couponPage.tabCoupon}
                </a>
                <a
                  className=""
                  href="#"
                  data-page="/coupon/history"
                  onClick={handleGotoPage}
                >
                  {t.couponPage.tabCouponHistory}
                </a>
              </div>
            </section>

            <section id="pSearchMain">
              <div className="bSearch">
                <input
                  type="text"
                  name="search"
                  id="inputSearch"
                  placeholder={t.couponPage.searchPlaceHolder}
                  onChange={handleOnSearchChange}
                />
              </div>
              <div className="bFilter">
                <div className="select">
                  <select defaultValue={"all"} onChange={handleOnSortChange}>
                    <option value={"all"} disabled>
                      {t.couponPage.sortPlaceHolder}
                    </option>
                    <option value={"to_date"}>
                      {t.couponPage.sortExpired}
                    </option>
                    <option value={"discount"}>
                      {t.couponPage.sortDiscount}
                    </option>
                  </select>
                </div>
              </div>
            </section>

            <section id="pListCouponMain">
              <div className="bListCoupon">
                {articleItem.length === 0 ? (
                  <div className="bNoData">
                    {isLoading ? (
                      <TextLoader />
                    ) : (
                      <>
                        <img src={icoCouponGray} alt="" />
                        <p>{t.couponPage.cardNotFound}</p>
                      </>
                    )}
                  </div>
                ) : (
                  <>
                    {/* <div className="bGroup">{articleItem}</div> */}
                    <div className="bGroup">
                      <MyCoupon articleItem={articleItem} />
                    </div>
                    <div className="bNoData hide">
                      <img src={icoCouponGray} alt="" />
                      <p>{t.couponPage.cardNotFound}</p>
                    </div>
                  </>
                )}
              </div>
            </section>

            <MenuMember pageActive={"coupon"} />
          </div>
        </main>
      </div>
    </>
  );
};

export default Coupon;
