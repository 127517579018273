import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import $ from "jquery";
import jBox from "jbox";

import useTranslations from "../../i18n/useTranslations";

const ModalCouponAlert = ({
  open = false,
  onClose = () => {},
  submitCallback = () => {},
}) => {
  const { t } = useTranslations(); // แปลภาษา

  const [modalAlert, setModalAlert] = useState(null);
  const [clickClose, setClickClose] = useState(false); //block twic click close
  const [clickSubmit, setClickSubmit] = useState(false); //block twic click submit

  const openModal = () => {
    modalAlert.open();
  };

  const closeModal = () => {
    modalAlert.close();
    onClose();
  };

  const listenerCloseModal = () => {
    if (!clickClose) {
      const btnCloseModalAlert = document.getElementById("btnCloseModalAlert");
      btnCloseModalAlert.addEventListener("click", () => {
        closeModal();
        setClickClose(true);
      });
    }
  };

  const listenerSubmitAlert = () => {
    if (!clickSubmit) {
      const btnSubmitAlter = document.getElementById("btnSubmitAlter");
      btnSubmitAlter.addEventListener("click", () => {
        submitCallback(true);
        closeModal();
        setClickSubmit(true);
      });
    }
  };

  /* Handle goto page */
  // const navigate = useNavigate();

  // const handleGotoQrCode = () => {
  //   navigate("/coupon/qrcode");
  // };
  /* End Handle goto page */

  /* First event render */
  useEffect(() => {
    const initModalAlert = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalConfirm"),
    });
    setModalAlert(initModalAlert);

    return () => {
      initModalAlert.destroy();
    };
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log("modalAlert useEffect2!! >>", modalAlert, open);

    if (modalAlert && open) {
      openModal();
      listenerCloseModal();
      // setTimeout(() => listenerCloseModal(), 100)

      listenerSubmitAlert();
    }
  }, [modalAlert, open]);

  return (
    <div className="bModal bCard" id="ModalConfirm">
      <div className="bInner">
        <div className="bTitle">
          <span>{t.lang === "en" ? "Are you confirm to use this coupon?" : "ต้องการยืนยันการใช้คูปอง"}</span>
        </div>
        <div className="bBtn">
          <a className="btn outline btnCloseModal" id="btnCloseModalAlert">
            {t.lang === "en" ? "Cancel" : "ยกเลิก"}
          </a>
          <a className="btn" id="btnSubmitAlter">
          {t.lang === "en" ? "Confirm" : "ยืนยัน"}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalCouponAlert;
